/* global document: false */
/* globals S3P_SETTINGS: true */
import { hot } from 'react-hot-loader/root'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import {sessionStore, localStore} from './storage'
import { applyRouterMiddleware, Router } from 'react-router'
import { syncHistoryWithStore, routerActions } from 'react-router-redux'
import { useScroll } from 'react-router-scroll'
import initializeRouter from './router'
import { Provider } from 'react-redux'
import { oauthAccessTokenSelector } from 's3p-js-lib/src/redux/selectors/api/auth/oauth-token'
import * as OfflinePluginRuntime from 'offline-plugin/runtime'
import 'core-js/stable'
// eslint-disable-next-line no-unused-vars
import regeneratorRuntime from 'regenerator-runtime'
import applySentryBrowser from './telemetry/sentry'
import {captureException, isSentryEnabled} from '@s3p-js-deep-purple/sentry'
import Translate from 's3p-js-lib/src/translate'
import ResponseCodes from 's3p-js-lib/src/api/response-codes'
import moment from 'moment'

OfflinePluginRuntime.install()

const runApp = () => {
  Translate.initialize()
  moment.locale(Translate.getLocale())

  const extraSystemErrors = [ResponseCodes.USER_INVALID_SESSION_ID_FOR_AGENT]
  ResponseCodes.addResponseCodes({
    USER_AGENT_IS_NOT_AUTHORIZED_TO_VIEW_BOOKING: 1036,
    USER_AGENT_NOT_AUTHORIZED_MULTIPLE_DEVICES: 1057
  })
  ResponseCodes.setCallbackSystemError(error => extraSystemErrors.includes(error.errorCode))
  ResponseCodes.addSeverityMap({
    [ResponseCodes.BOOKING_REACHED_RESEND_LIMIT]: ResponseCodes.severity.info,
    [ResponseCodes.USER_AGENT_IS_NOT_AUTHORIZED_TO_VIEW_BOOKING]: ResponseCodes.severity.info,
    [ResponseCodes.ORIENTATION_DEEP_LINK_NO_ROUTE_FOUND]: ResponseCodes.severity.info,
    [ResponseCodes.ORIENTATION_DEEP_LINK_NO_BUNDLE_FOUND]: ResponseCodes.severity.info,
    [ResponseCodes.USER_AGENT_NOT_AUTHORIZED_MULTIPLE_DEVICES]: ResponseCodes.severity.warning,
    [ResponseCodes.API_DOCUMENT_TEMPLATES_NOT_FOUND]: ResponseCodes.severity.info
  })
  ResponseCodes.addMessages({
    [ResponseCodes.USER_AGENT_NOT_AUTHORIZED_MULTIPLE_DEVICES]: 'messages.agent-not-allowed-multiple-devices'
  })

  if (!(sessionStore.available && localStore.available)) {
    const SystemErrorLayout = require('./layouts/error/system-error')
    ReactDOM.render(
      <SystemErrorLayout />,
      document.getElementById('app')
    )
    return
  }

  const {default: Loader, TYPE_BLOCKING} = require('./elements/loader/loader')
  const {default: createReduxStore} = require('./redux/store')
  const {default: AppContainer} = require('./app-container')
  const {loadSession} = require('./redux/actions/containers/app')
  const reactHistory = initializeRouter()
  const reduxStore = createReduxStore()
  syncHistoryWithStore(reactHistory, reduxStore)
  window.reduxStore = reduxStore
  const {default: routeCallback} = require('./routes')

  class App extends Component {
    static propTypes = {
      params: PropTypes.shape({
        lang: PropTypes.string
      }).isRequired
    }

    constructor (props) {
      super(props)
      this.state = {showBlockingLoader: false}
    }

    UNSAFE_componentWillMount () {
      if (oauthAccessTokenSelector(reduxStore.getState())) {
        this.setState({showBlockingLoader: true})
        reduxStore.dispatch(loadSession())
          .then(() => this.setState({showBlockingLoader: false}))
          .catch(err => {
            if (err && isSentryEnabled()) {
              captureException(err, scope => {
                if (err.message) {
                  scope.setExtra({message: err.message})
                }
                return scope
              })
            }
            window.console && console.error(err)
          })
      }

      this._updateTitleAndMetaDescription()
      this._updateLocaleInRoute(this.props)
    }

    _updateLocaleInRoute (props) {
      let path = props.location.pathname
      if (path === '/' || path.length === 0) {
        path = S3P_SETTINGS.s3Passenger.features.unauthorizedPages.default
      }
      if (!path.startsWith(`/${Translate.getLocale()}`)) {
        path = `/${Translate.getLocale()}${path}`
      }
      reduxStore.dispatch(routerActions.replace(`${path}${props.location.search}`))
    }

    _updateTitleAndMetaDescription () {
      document.title = Translate.message('title')

      const meta = document.getElementsByTagName('meta')
      for (let i = 0; i < meta.length; i++) {
        if (meta[i].name.toLowerCase() === 'description') {
          meta[i].content = Translate.message('meta.description')
        }
      }
    }

    render () {
      return (
        <Provider store={reduxStore}>
          {this.state.showBlockingLoader || this.props.children == null
            ? <Loader type={TYPE_BLOCKING} />
            : (
              <AppContainer>
                {React.cloneElement(this.props.children, {lang: Translate.getLocale()})}
              </AppContainer>
              ) }
        </Provider>
      )
    }
  }

  let routes
  if (module.hot) {
    // eslint-disable-next-line no-console
    window.console && console.log('Hot module replacement enabled!')
    routes = routeCallback(hot(App))
  } else {
    routes = routeCallback(App)
  }

  // Render only if #app is available
  const renderDOMComponent = document.getElementById('app')
  if (renderDOMComponent) {
    ReactDOM.render(
      <Router
        history={reactHistory}
        routes={routes}
        render={applyRouterMiddleware(useScroll((prevRouterProps, {location}) => {
          window?.newrelic?.addPageAction(`route ${location.action} ${location.pathname}`, location)
          return (
            prevRouterProps && location.pathname !== prevRouterProps.location.pathname
          )
        }))}
      />,
      renderDOMComponent
    )
  }
}

applySentryBrowser(runApp)()
