import request from 's3p-js-lib/src/api/request'
import {stringifyQuery} from 's3p-js-lib/src/misc/utils'
import { defaultRetryInstance } from 's3p-js-lib/src/misc/retry-manager'
import omitBy from 'lodash/omitBy'
import isUndefined from 'lodash/isUndefined'
// eslint-disable-next-line import/no-unresolved
import Client from 's3p-js-lib/src/api/client'
import {clientRequestsV2} from '@s3p-js-deep-purple/api-client-requests'
import {HTTP_CODE_NOT_FOUND, RETRYABLE_ERROR_HTTP_CODES} from 's3p-js-lib/src/constants'

const preparePassengerDetailsData = passenger => omitBy({
  id: passenger.id,
  first_name: passenger.firstName,
  last_name: passenger.lastName,
  title: passenger.title || undefined,
  gender: passenger.gender || undefined,
  email: passenger.emailAddress || undefined,
  phone_number: passenger.phoneNumber || undefined,
  birth_date: passenger.birthDate || undefined,
  nationality: passenger.nationality || undefined,
  protect_privacy: 'protectPrivacy' in passenger ? passenger.protectPrivacy : undefined
}, isUndefined)

const prepareCustomerData = customerDetails => {
  if (customerDetails) {
    const customerData = {
      title: customerDetails.title === '' ? undefined : customerDetails.title,
      first_name: customerDetails.firstName,
      last_name: customerDetails.lastName,
      address: {
        postal_code: customerDetails.postalCode,
        street: customerDetails.street,
        house_number: customerDetails.houseNumber,
        city: customerDetails.city,
        country_code: customerDetails.country
      },
      email: customerDetails.emailAddress === '' ? undefined : customerDetails.emailAddress,
      phone_number: customerDetails.phoneNumber === '' ? undefined : customerDetails.phoneNumber,
      phone_numbers: customerDetails.phoneNumbers
    }
    if (customerDetails.id) {
      customerData.crm_customer_id = customerDetails.id
      customerData.crm_reference = `${customerDetails.id}`
    }
    return customerData
  }
}

const BOMClient = {
  getMagstripeTickets (token, bookingNumber, fulfillmentMethodCode, pinCode) {
    const query = {
      fulfillment_method_code: fulfillmentMethodCode,
      pin_code: pinCode
    }

    return request({
      method: 'get',
      endpoint: `/api/modules/irish-rail/tod/magstripe/${bookingNumber}?${stringifyQuery(query)}`,
      token
    })
  },

  confirmMagstripe (token, bookingNumber, {ticketNumbers, fulfillmentDetail}) {
    const data = {}
    if (ticketNumbers) {
      data.ticket_numbers = ticketNumbers
    }
    if (fulfillmentDetail) {
      data.meta_data = fulfillmentDetail
    }
    return request({
      method: 'post',
      endpoint: `/api/modules/irish-rail/tod/magstripe/${bookingNumber}/confirm`,
      data,
      token
    })
  },

  leapConfigurationData (token) {
    return request({
      method: 'get',
      endpoint: '/api/modules/irish-rail/leap/configuration-data',
      token
    })
  },

  leapConfigurationDataSet (token, deviceId, scimId, samId) {
    return request({
      method: 'get',
      endpoint: `/api/modules/irish-rail/leap/configuration-data-set/${deviceId}/${scimId}/${samId}`,
      token
    })
  },

  leapConfigurationDataSetConfirm (token, deviceId, scimId, samId, data) {
    return request({
      method: 'post',
      endpoint: `/api/modules/irish-rail/leap/configuration-data-set/${deviceId}/${scimId}/${samId}/confirm`,
      data,
      token
    })
  },

  leapSchemeWideCode (token) {
    return request({
      endpoint: '/api/modules/irish-rail/leap/scheme-wide-code',
      method: 'get',
      token
    })
  },

  getLeapActionList (token, cardNumber, samId) {
    return request({
      endpoint: `/api/modules/irish-rail/leap/card/${cardNumber}/actions?${stringifyQuery({samId})}`,
      method: 'get',
      token
    })
  },

  confirmLeapAction (token, cardNumber, actionId, response, status) {
    return request({
      endpoint: `/api/modules/irish-rail/leap/card/${cardNumber}/action/${actionId}/confirm`,
      method: 'post',
      data: {response, status},
      token
    })
  },

  rebookingCreateBooking (token, data) {
    return clientRequestsV2.rebookBooking({token}, data)
  },

  changeSeatsSelectSeats (token, bookingNumber, selectedSeats, waiveFees, overrideAftersalesRules, overrideValidationsRules) {
    return clientRequestsV2.updateSeats({token}, {
      bookingNumber,
      seats: selectedSeats.map(seat => ({
        carriage: seat.carriageNumber,
        number: seat.seatNumber,
        item_ref: seat.product.item_ref
      })),
      override_aftersales_fees: waiveFees,
      override_aftersales_rules: overrideAftersalesRules,
      override_validation_rules: overrideValidationsRules
    })
  },

  async updatePassengersUpdate (token, bookingNumber, passengerDetails, waiveFees, overrideAftersalesRules, overrideValidationsRules) {
    return clientRequestsV2.updatePassengers({token}, {
      bookingNumber,
      passengers: passengerDetails.map(preparePassengerDetailsData),
      override_aftersales_fees: waiveFees,
      override_aftersales_rules: overrideAftersalesRules || undefined,
      override_validation_rules: overrideValidationsRules
    })
  },

  resendTicketsByBookingNumber (token, bookingNumber, overrideValidationRules) {
    return clientRequestsV2.resendTickets({token}, {
      booking_number: bookingNumber,
      override_validation_rules: overrideValidationRules
    })
  },

  journeySearch (token, bookingNumber, data) {
    return clientRequestsV2.journeySearch({token}, data)
  },

  journeySearchForExchange (token, bookingNumber, data) {
    return clientRequestsV2.journeySearchForExchange({token}, {
      bookingNumber,
      ...data
    })
  },

  productSearch (token, data) {
    return clientRequestsV2.productSearch({token}, data)
  },

  async addCustomer (token, bookingNumber, customer) {
    return clientRequestsV2.addCustomer({token}, {bookingNumber, customer: prepareCustomerData(customer)})
  },

  async getCalendar (token, data) {
    return clientRequestsV2.getCalendar({token}, data)
  },

  async updateCustomer (token, bookingNumber, customer) {
    return clientRequestsV2.updateCustomer({token}, {bookingNumber, customer: prepareCustomerData(customer)})
  },

  async logoutUser (token) {
    return clientRequestsV2.userLogout({token})
  }
}

export default {
  getMagstripeTickets: defaultRetryInstance.patchCallWithRetry(BOMClient.getMagstripeTickets),
  confirmMagstripe: defaultRetryInstance.patchCallWithRetry(BOMClient.confirmMagstripe),
  leapConfigurationData: defaultRetryInstance.patchCallWithRetry(BOMClient.leapConfigurationData),
  leapConfigurationDataSet: defaultRetryInstance.patchCallWithRetry(BOMClient.leapConfigurationDataSet),
  leapConfigurationDataSetConfirm: defaultRetryInstance.patchCallWithRetry(BOMClient.leapConfigurationDataSetConfirm),
  leapSchemeWideCode: defaultRetryInstance.patchCallWithRetry(BOMClient.leapSchemeWideCode),
  getLeapActionList: defaultRetryInstance.patchCallWithRetry(BOMClient.getLeapActionList),
  confirmLeapAction: defaultRetryInstance.patchCallWithRetry(BOMClient.confirmLeapAction),
  resendTicketsByBookingNumber: defaultRetryInstance.patchCallWithRetry(BOMClient.resendTicketsByBookingNumber),
  loadUserInformation: defaultRetryInstance.patchCallWithRetry(Client.loadUserInformation),
  loadAgentUserInformation: defaultRetryInstance.patchCallWithRetry(Client.loadAgentUserInformation),
  loadStations: defaultRetryInstance.patchCallWithRetry(Client.loadStations),
  loadCmsBlocks: defaultRetryInstance.patchCallWithRetry(Client.loadCmsBlocks),
  updateAgent: defaultRetryInstance.patchCallWithRetry(Client.updateAgent),
  getBookings: defaultRetryInstance.patchCallWithRetry(Client.getBookings),
  getBooking: defaultRetryInstance.patchCallWithRetry(Client.getBooking),
  createProvisionalBooking: defaultRetryInstance.patchCallWithRetry(Client.createProvisionalBooking, {injectS3IdempotencyKeyHeader: true}),
  createBooking: defaultRetryInstance.patchCallWithRetry(Client.createBooking, {injectS3IdempotencyKeyHeader: true}),
  cancelBooking: defaultRetryInstance.patchCallWithRetry(Client.cancelBooking),
  getOffer: defaultRetryInstance.patchCallWithRetry(Client.getOffer),
  rebookingJourneySearch: defaultRetryInstance.patchCallWithRetry(Client.rebookingJourneySearch),
  rebookingCreateBooking: defaultRetryInstance.patchCallWithRetry(BOMClient.rebookingCreateBooking),
  addVoucher: defaultRetryInstance.patchCallWithRetry(Client.addVoucher),
  removeVoucher: defaultRetryInstance.patchCallWithRetry(Client.removeVoucher),
  requestRefund: defaultRetryInstance.patchCallWithRetry(Client.requestRefund),
  addCustomerToBooking: defaultRetryInstance.patchCallWithRetry(Client.addCustomerToBooking),
  updateBookingCustomer: defaultRetryInstance.patchCallWithRetry(Client.updateBookingCustomer),
  getPaymentMethods: defaultRetryInstance.patchCallWithRetry(Client.getPaymentMethods),
  getCarriageLayoutsOrientation: defaultRetryInstance.patchCallWithRetry(Client.getCarriageLayoutsOrientation),
  getCarriageLayoutsAftersales: defaultRetryInstance.patchCallWithRetry(Client.getCarriageLayoutsAftersales),
  changeSeatsInitiate: defaultRetryInstance.patchCallWithRetry(Client.changeSeatsInitiate),
  changeSeatsSelectSeats: defaultRetryInstance.patchCallWithRetry(BOMClient.changeSeatsSelectSeats),
  cancellationInitiate: defaultRetryInstance.patchCallWithRetry(Client.cancellationInitiate),
  cancellationCancel: defaultRetryInstance.patchCallWithRetry(Client.cancellationCancel),
  rebookInitiate: defaultRetryInstance.patchCallWithRetry(Client.rebookInitiate),
  updatePassengersInitiate: defaultRetryInstance.patchCallWithRetry(Client.updatePassengersInitiate),
  updatePassengersUpdate: defaultRetryInstance.patchCallWithRetry(BOMClient.updatePassengersUpdate),
  addBookingNote: defaultRetryInstance.patchCallWithRetry(Client.addBookingNote),
  aftersalesConfirm: defaultRetryInstance.patchCallWithRetry(Client.aftersalesConfirm),
  additionalProductsInitiate: defaultRetryInstance.patchCallWithRetry(Client.additionalProductsInitiate),
  findCustomers: defaultRetryInstance.patchCallWithRetry(Client.findCustomers),
  getJourneyDetails: defaultRetryInstance.patchCallWithRetry(Client.getJourneyDetails),
  getDocumentTemplates: defaultRetryInstance.patchCallWithRetry(Client.getDocumentTemplates),
  startAgentShift: defaultRetryInstance.patchCallWithRetry(Client.startAgentShift),
  getAgentShift: defaultRetryInstance.patchCallWithRetry(Client.getAgentShift),
  endAgentShift: defaultRetryInstance.patchCallWithRetry(Client.endAgentShift),
  addNotesAgentShift: defaultRetryInstance.patchCallWithRetry(Client.addNotesAgentShift),
  getVouchers: defaultRetryInstance.patchCallWithRetry(Client.getVouchers),
  ticketVoidValidation: defaultRetryInstance.patchCallWithRetry(Client.ticketVoidValidation),
  getTextFile: defaultRetryInstance.patchCallWithRetry(
    Client.getTextFile,
    {
      defaultRetryableErrorHttpCodes: [HTTP_CODE_NOT_FOUND, ...RETRYABLE_ERROR_HTTP_CODES],
      firstIntervalDelay: 1000,
      offlineFeedbackMessage: 'offline.title-fetching-ticket'
    }
  ),
  headAgentShift: defaultRetryInstance.patchCallWithRetry(Client.headAgentShift),
  journeySearch: defaultRetryInstance.patchCallWithRetry(BOMClient.journeySearch),
  productSearch: defaultRetryInstance.patchCallWithRetry(BOMClient.productSearch),
  productSearchForTickets: defaultRetryInstance.patchCallWithRetry(Client.getProducts),
  journeySearchForExchange: defaultRetryInstance.patchCallWithRetry(BOMClient.journeySearchForExchange),
  ping: Client.ping,
  addCustomer: defaultRetryInstance.patchCallWithRetry(BOMClient.addCustomer),
  getCalendar: defaultRetryInstance.patchCallWithRetry(BOMClient.getCalendar),
  updateCustomer: defaultRetryInstance.patchCallWithRetry(BOMClient.updateCustomer),
  logoutUser: defaultRetryInstance.patchCallWithRetry(BOMClient.logoutUser),
  confirmBooking: defaultRetryInstance.patchCallWithRetry(Client.confirmBooking),
  addAdditionalDetails: defaultRetryInstance.patchCallWithRetry(Client.addAdditionalDetails),
  updateAdditionalDetails: defaultRetryInstance.patchCallWithRetry(Client.updateAdditionalDetails),
  addPayments: defaultRetryInstance.patchCallWithRetry(Client.addPayments),
  updatePayments: defaultRetryInstance.patchCallWithRetry(Client.updatePayments),
  updateFulfillmentMethod: defaultRetryInstance.patchCallWithRetry(Client.updateFulfillmentMethod),
  updateSegments: defaultRetryInstance.patchCallWithRetry(Client.updateSegments)
}
